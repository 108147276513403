
.rdw-editor-toolbar {
  padding: .65rem .3rem .3rem;
  border: 0;
  margin-bottom: 0;
  border: 1px solid rgba(0,0,0,.2);
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: .625rem;
  border-top-right-radius: .625rem;
  background-color: #0F2F3C !important;
}

.rdw-option-wrapper {
  border: 0 !important;
}
.rdw-dropdown-selectedtext {
  color: inherit;
}

.rdw-editor-toolbar {
  background-color: #fff;
}

.rdw-editor-main {
  font-size: 1rem;
}


.rdw-option-wrapper {
  box-shadow: none;
  border: 1px solid transparent !important;
  border-radius: 3px;
}

.rdw-option-wrapper:hover {
  box-shadow: none;
  border: 1px solid transparent !important;
  background: #f8f8f8;
}

.rdw-option-active, .rdw-option-active:hover {
  box-shadow: none;
  border: 1px solid rgba(255,255,255,.35) !important;
  background: #fafafa;
}

a.rdw-dropdown-selectedtext {
  color: #333;
  padding-left: 10px;
}

.rdw-editor-main {
  font-family: 'Ambit';
  font-size: 1.25rem;
  font-weight: 400;
}

.rdw-dropdown-wrapper, .rdw-block-dropdown {
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.2);
}
.rwd-dropdown-wrapper:hover, .rdw-block-dropdown:hover {
  box-shadow: none !important;
  border-radius: 3px;
  border: 1px solid #000 !important;
}
.rdw-dropdown-wrapper:active, .rdw-block-dropdown:active {
  box-shadow: none !important;
  border: 1px solid #fff !important;
}

.rdw-dropdownoption-default:hover {
  background: #f4f4f4;
}
.rdw-dropdownoption-active, .rdw-dropdownoption-active:hover {
  background: #888;
  color: #fff;
}
.rdw-dropdown-optionwrapper {
  overflow-y: auto;
  box-shadow: none;
  border: 0;
  box-shadow: 0 0 8px rgba(0,0,0,.3) !important;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 0 0 8px rgba(0,0,0,.3) !important;
}
.rdw-dropdown-selectedtext .rdw-dropdown-carettoopen, .rdw-dropdown-selectedtext .rdw-dropdown-carettoclose {
  margin-top: 2px;
}
.rdw-dropdown-selectedtext span {
  margin-top: 1px;
}

.rdw-option-wrapper {
  background-color: #0F2F3C !important;
}

.rdw-option-wrapper img {
  filter: invert(1);
}

.DraftEditor-editorContainer .public-DraftStyleDefault-block {
  margin: 1rem 0 !important;
}

.DraftEditor-editorContainer h1 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;

  margin: 0 !important;
  padding: 0 !important;
}

.DraftEditor-editorContainer h2 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;

  margin: 0 !important;
  padding: 0 !important;
}

.DraftEditor-editorContainer h3 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;

  margin: 0 !important;
  padding: 0 !important;
}

.DraftEditor-editorContainer h4 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;

  margin: 0 !important;
  padding: 0 !important;
}
