body {
  margin: 0;
  font-family: "Ambit", sans-serif;
}
* {
  font-family: "Ambit", sans-serif;
}

p {
  margin: 0;
}

a {
  color: #0288D1;
}

textarea, input {
  outline: none;
  -webkit-appearance: none;
}

.rsm-geography:focus {
  outline: none !important;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html {
  font-size: 0.875rem !important;
}

@media only screen and (min-width: 1441px) {
  html {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 1.125rem !important;
  }
}
